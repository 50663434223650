import { defineStore } from "pinia";

export const useTrailStore = defineStore("trail", {
	state: () => ({
		filters: {
			q: "",
			r: "",
			a: "",
		},
		isSearching: false,
		trails: [
			{
				name: "Stansbury Island Trail",
				slug: "stansbury-island-trail",
				region: "north-tooele-valley",
				activities: ["hike", "horseback", "mountain-bike", "atv"],
				images: ["StansburyIslandTrail.jpg", "StansburyIslandTrail2.jpg"],
			},
			{
				name: "Lion Hill Loop",
				slug: "lion-hill-loop",
				region: "rush-valley",
				activities: ["hike", "horseback", "mountain-bike", "atv"],
				images: ["LionHillLoop.jpg", "LionHillLoop2.jpg"],
			},
			{
				name: "Jacob City Loop",
				slug: "jacob-city-loop",
				region: "rush-valley",
				activities: ["hike", "horseback", "mountain-bike", "atv"],
				images: ["JacobCityLoop.jpg", "JacobCityLoop2.jpg"],
			},
			{
				name: "Mid-Valley Trail",
				slug: "mid-valley-trail",
				region: "central-tooele-valley",
				activities: ["hike", "horseback", "mountain-bike"],
				images: ["MidValleyTrail.jpg", "MidValleyTrail2.jpg"],
			},
			{
				name: "Oquirrh Wave/Serengeti Trail System",
				slug: "oquirrh-wave",
				region: "eastern-tooele-valley",
				activities: ["hike", "horseback", "mountain-bike", "atv"],
				images: [
					"OquirrhWaveSerengetiTrailSystem.jpg",
					"OquirrhWaveSerengetiTrailSystem2.jpg",
				],
			},
			{
				name: "Dark Trail",
				slug: "dark-trail",
				region: "eastern-tooele-valley",
				activities: ["hike", "horseback", "mountain-bike"],
				images: ["DarkTrail.jpg", "DarkTrail2.jpg"],
			},
			{
				name: "Left Hand Fork",
				slug: "left-hand-fork",
				region: "eastern-tooele-valley",
				activities: ["hike", "horseback", "mountain-bike"],
				images: ["LeftHandFork.jpg", "LeftHandFork2.jpg"],
			},
			{
				name: "Mormon Trail Loop",
				slug: "mormon-trail-loop",
				region: "central-tooele-valley",
				activities: ["road-bike", "mountain-bike"],
				images: ["MormonTrailLoop.jpg", "MormonTrailLoop2.jpg"],
			},
			{
				name: "Tooele Valley Overlook",
				slug: "tooele-valley-overlook",
				region: "eastern-tooele-valley",
				activities: ["hike", "road-bike", "mountain-bike"],
				images: ["TooeleValleyOverlook.jpg", "TooeleValleyOverlook2.jpg"],
			},

			// {
			// 	name: "Davenport Canyon",
			// 	slug: "davenport-canyon",
			// 	region: "central-tooele-valley",
			// 	activities: ["hike", "horseback", "mountain-bike", "atv"],
			// 	images: ["DavenportCanyon.jpg", "DavenportCanyon2.jpg"],
			// },
			// {
			// 	name: "Deseret Peak Trail",
			// 	slug: "deseret-peak-trail",
			// 	region: "central-tooele-valley",
			// 	activities: ["hike", "horseback"],
			// 	images: ["DeseretPeakTrail.jpg", "DeseretPeakTrail2.jpg"],
			// },
			// {
			// 	name: "Stansbury Front Trail",
			// 	slug: "stansbury-front-trail",
			// 	region: "central-tooele-valley",
			// 	activities: ["hike", "horseback", "mountain-bike", "atv"],
			// 	images: ["StansburyFrontTrail.jpg", "StansburyFrontTrail2.jpg"],
			// },
			// {
			// 	name: "Tooele Cross-Town",
			// 	slug: "tooele-cross-town",
			// 	region: "central-tooele-valley",
			// 	activities: ["hike", "road-bike", "mountain-bike"],
			// 	images: ["TooeleCrossTown.jpg", "TooeleCrossTown2.jpg"],
			// },
			// {
			// 	name: "Copper Pit Overlook",
			// 	slug: "copper-pit-overlook",
			// 	region: "eastern-tooele-valley",
			// 	activities: ["hike", "horseback", "mountain-bike", "atv"],
			// 	images: ["CopperPitOverlook.jpg", "CopperPitOverlook2.jpg"],
			// },
			// {
			// 	name: "Benson Gristmill Loop",
			// 	slug: "benson-gristmill-loop",
			// 	region: "north-tooele-valley",
			// 	activities: ["road-bike", "mountain-bike"],
			// 	images: ["BensonGristmillLoop.jpg", "BensonGristmillLoop2.jpg"],
			// },
			// {
			// 	name: "Knolls Recreation Area",
			// 	slug: "knolls-recreation-area",
			// 	region: "north-tooele-valley",
			// 	activities: ["mountain-bike", "atv"],
			// 	images: ["KnollsRecreationArea.jpg", "KnollsRecreationArea2.jpg"],
			// },
			// {
			// 	name: "South Mountain Loop",
			// 	slug: "south-mountain-loop",
			// 	region: "rush-valley",
			// 	activities: ["hike", "road-bike", "mountain-bike", "atv"],
			// 	images: ["SouthMountainLoop.jpg", "SouthMountainLoop2.jpg"],
			// },
			// {
			// 	name: "Five Mile Pass Recreation Area",
			// 	slug: "five-mile-pass",
			// 	region: "south-rush-valley",
			// 	activities: ["mountain-bike", "atv"],
			// 	images: [
			// 		"FiveMilePassRecreationArea.jpg",
			// 		"FiveMilePassRecreationArea2.jpg",
			// 	],
			// },
			// {
			// 	name: "Pony Express Trail",
			// 	slug: "pony-express-trail",
			// 	region: "south-rush-valley",
			// 	activities: ["hike", "horseback", "mountain-bike", "atv"],
			// 	images: ["PonyExpressTrail.jpg", "PonyExpressTrail2.jpg"],
			// },
			// {
			// 	name: "Rush Valley Loop",
			// 	slug: "rush-valley-loop",
			// 	region: "south-rush-valley",
			// 	activities: ["road-bike", "mountain-bike"],
			// 	images: ["RushValleyLoop.jpg", "RushValleyLoop2.jpg"],
			// },
		],
		regions: [
			{
				name: "Central Tooele Valley",
				slug: "central-tooele-valley",
			},
			{
				name: "Eastern Tooele Valley",
				slug: "eastern-tooele-valley",
			},
			{
				name: "North Tooele Valley",
				slug: "north-tooele-valley",
			},
			{
				name: "Rush Valley",
				slug: "rush-valley",
			},
			{
				name: "South Rush Valley",
				slug: "south-rush-valley",
			},
		],
		activities: [
			{
				slug: "hike",
				name: "Hike",
				icon: "hike.png",
			},
			{
				slug: "road-bike",
				name: "Road Bike",
				icon: "road-bike.png",
			},
			{
				slug: "horseback",
				name: "Horseback",
				icon: "horseback.png",
			},
			{
				slug: "mountain-bike",
				name: "Mountain Bike",
				icon: "mountain-bike.png",
			},
			{
				slug: "atv",
				name: "ATV/OHVs",
				icon: "atv.png",
			},
		],
	}),
	getters: {
		completeTrails: (state) => {
			return state.trails.map((item) => {
				return {
					...item,
					region: state.getTrailRegion(item),
					activities: state.getTrailActivities(item),
					images: state.getTrailImages(item),
				};
			});
		},
		filteredTrails: (state) => {
			return state.completeTrails.filter(
				(item) =>
					(!state.filters.q ||
						item.name.toLowerCase().includes(state.filters.q.toLowerCase())) &&
					(!state.filters.r || item.region.slug === state.filters.r) &&
					(!state.filters.a ||
						item.activities.find((item) => item.slug === state.filters.a))
			);
		},
	},
	actions: {
		getTrailBySlug(slug) {
			return this.completeTrails.find((item) => {
				return item.slug === slug;
			});
		},
		getTrailRegion(trail) {
			return this.regions.find((item) => {
				return item.slug === trail.region;
			});
		},
		getTrailActivities(trail) {
			return this.activities
				.filter((item) => {
					return trail.activities.includes(item.slug);
				})
				.map((item) => {
					return {
						...item,
						icon: new URL(
							`./assets/icons/${item.icon}`,
							tooele_trails_map_object.url
						).href,
					};
				});
		},
		getTrailImages(trail) {
			return trail.images.map((item) => {
				return new URL(`./assets/${item}`, tooele_trails_map_object.url).href;
			});
		},
	},
});
